import Header from './Header'
import Footer from './Footer'
import React from 'react'
import clsx from 'clsx'
import { LogoAttr } from '../../typings/base'
import Head from 'next/head'
import { useScrollState } from '../../utils/scrollState'
import SharedHead from '../Global/SharedHead'
import { isPrismicText, prismicText } from '../Global/CMSText'

type Tprops = {
  children: JSX.Element[] | JSX.Element
  doc?: any
  account?: any
  faq?: any
  hideTop?: boolean
  mainLogo: LogoAttr
  banner?: any
  t?: any
  headerTitle?: any
  metaTitle?: any
  metaDescription?: any
  metaKeywords?: any
  metaDescriptionProduct?: any
  metaImage?: any
  inStoreText: any
  metaKeywordsProduct?: any
  pageType?: string
  basket: any
  takeback?: any
  pageName?: string
}

const Layout = ({
  children,
  doc,
  account,
  faq,
  hideTop,
  mainLogo,
  headerTitle,
  t,
  inStoreText,
  banner,
  metaTitle,
  metaDescription,
  metaKeywords,
  metaDescriptionProduct,
  metaKeywordsProduct,
  metaImage,
  pageType,
  takeback,
  basket,
  pageName,
}: Tprops) => {
  const scrollState = useScrollState()
  const isBanner = isPrismicText(banner?.data?.text)
  const isDisplayedBannerOnAllPages =
    banner?.data?.is_displayed_banner_on_all_pages

  const metaTitleString =
    metaTitle && isPrismicText(doc?.data?.baseline)
      ? `${prismicText(doc?.data?.baseline)} - ${metaTitle}`
      : metaTitle

  return (
    <div className={clsx('page', pageType && `page--${pageType}`)}>
      <SharedHead datas={doc} />
      <Head>
        {metaTitleString && (
          <>
            <title key="pageTitle">{metaTitleString}</title>
            <meta property="og:title" name="title" content={metaTitleString} />
          </>
        )}
        {metaDescription && (
          <meta
            property="og:description"
            name="description"
            content={metaDescription}
          />
        )}
        {metaKeywords?.length && metaKeywords[0]?.text && (
          <meta
            property="og:keywords"
            name="keywords"
            content={prismicText(metaKeywords)}
          />
        )}
        {metaKeywordsProduct && (
          <meta
            property="og:keywords"
            name="keywords"
            content={metaKeywordsProduct}
          />
        )}
        {metaDescriptionProduct && (
          <meta
            property="og:description"
            name="description"
            content={metaDescriptionProduct}
          />
        )}
        {metaImage && (
          <>
            <meta property="og:image" content={metaImage} />
            <meta name="twitter:image" content={metaImage} />
          </>
        )}
      </Head>
      <Header
        logo={mainLogo}
        t={t}
        title={headerTitle}
        inStoreText={inStoreText}
        doc={doc}
        account={account}
        faq={faq}
        showOnScrollUp={scrollState.showOnScrollUp}
        showOnTop={scrollState.showOnTop}
        textColor={doc?.data?.header_text_color}
        background={doc?.data?.header_background}
        hoverColor={doc?.data?.header_text_hover_color}
        basket={basket}
        takeback={takeback}
        submenuBackgroundColor={doc?.data?.header_submenu_background_color}
        submenuTextColor={doc?.data?.header_submenu_text_color}
        isBanner={isBanner}
        isDisplayedBannerOnAllPages={isDisplayedBannerOnAllPages}
        banner={banner}
        pageName={pageName}
      />
      {children}
      <Footer doc={doc} t={t} hideTop={hideTop} />
    </div>
  )
}

export default Layout
